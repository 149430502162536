import { gql } from "@apollo/client";

import { SESSION_USER_FRAGMENT } from "./session";

export const USER_FRAGMENT = gql`
  fragment UserFragment on User {
    id
    username
    email
    active
    role
    homeoffice
    bio
    customerId
    attrs
    lastSeenAt
    passwordReset
    otp

    avatar {
      thumb
      medium
      original
    }

    contact {
      id
      firstname
      lastname
      displayName
      companyName
      birthdate
    }
  }
`;

export const USER_MUTATION = gql`
  mutation mutateUser($customerId: ID!, $userId: ID, $user: UserInput!, $options: UserMutationOptions) {
    mutateUser(customerId: $customerId, userId: $userId, values: $user, options: $options) {
      ...UserFragment

      profiles {
        id
        name
      }
    }
  }
  ${USER_FRAGMENT}
`;

export const USER_PROFILE_MUTATION = gql`
  mutation mutateUserProfile($bio: String!) {
    mutateUserProfile(bio: $bio) {
      ...UserFragment
    }
  }
  ${USER_FRAGMENT}
`;

export const USER_PASSWORD_MUTATION = gql`
  mutation mutateUserPassword($password: String!) {
    mutateUserPassword(password: $password) {
      ...UserFragment

      profiles {
        id
        name
      }
    }
  }
  ${USER_FRAGMENT}
`;

export const USER_AVATAR_MUTATION = gql`
  mutation mutateUserAvatar($avatar: Upload!) {
    mutateUserAvatar(avatar: $avatar) {
      ...UserFragment

      profiles {
        id
        name
      }
    }
  }
  ${USER_FRAGMENT}
`;

export const USER_SIGNATURE_MUTATION = gql`
  mutation mutateUserSignature($signature: JSON!) {
    mutateUserSignature(signature: $signature) {
      ...UserFragment

      profiles {
        id
        name
      }
    }
  }
  ${USER_FRAGMENT}
`;

export const ENABLE_OTP_MUTATION = gql`
  mutation enableOtp {
    enableOtp
  }
`;

export const DISABLE_OTP_MUTATION = gql`
  mutation disableOtp($otp: String!) {
    disableOtp(otp: $otp) {
      ...SessionUserFragment
    }
  }
  ${SESSION_USER_FRAGMENT}
`;

export const DISABLE_OTP_FOR_USER_MUTATION = gql`
  mutation disableOtpForUser($customerId: ID!, $userId: ID!) {
    disableOtpForUser(customerId: $customerId, userId: $userId) {
      ...UserFragment

      profiles {
        id
        name
      }
    }
  }
  ${USER_FRAGMENT}
`;

export const USERS_QUERY = gql`
  query users($filters: UsersListFilter) {
    users(filters: $filters) {
      ...UserFragment
    }
  }
  ${USER_FRAGMENT}
`;

export const COUNT_USERS_QUERY = gql`
  query countUsers($filters: UsersCountFilter) {
    countUsers(filters: $filters)
  }
`;

export const USER_DELETE_MUTATION = gql`
  mutation deleteUser($userId: ID!, $inheritorId: ID!, $customerId: ID!) {
    deleteUser(userId: $userId, inheritorId: $inheritorId, customerId: $customerId) {
      id
    }
  }
`;

export const USER_GET_QUERY = gql`
  query user($customerId: ID!, $userId: ID!) {
    user(customerId: $customerId, userId: $userId) {
      ...UserFragment
    }
  }
  ${USER_FRAGMENT}
`;

export const USERS_STATUS_PANEL_QUERY = gql`
  query usersPhones($customerId: ID!, $phones: [String]!, $limit: Int) {
    usersPhones(customerId: $customerId, phones: $phones, limit: $limit) {
      id
      username
      homeoffice

      attrs

      employee {
        id
      }
    }
  }
`;

export const E164_UNIQUE_QUERY = gql`
  query e164Unique($e164: String!, $userId: ID) {
    e164Unique(e164: $e164, userId: $userId)
  }
`;

export const USERNAME_UNIQUE_QUERY = gql`
  query usernameUnique($username: String!, $userId: ID) {
    usernameUnique(username: $username, userId: $userId)
  }
`;

export const USER_PASSWORD_RESET_MUTATION = gql`
  mutation resetUserPassword(
    $customerId: ID!
    $userId: ID!
    $emailLang: String!
    $sendAs: UserPasswortSendAs!
    $template: UserPasswordMailType!
  ) {
    resetUserPassword(
      customerId: $customerId
      userId: $userId
      emailLang: $emailLang
      sendAs: $sendAs
      template: $template
    ) {
      ...UserFragment
    }
  }
  ${USER_FRAGMENT}
`;

export const USER_API_TOKEN_MUTATION = gql`
  mutation apiTokenForUser($customerId: ID!, $userId: ID!) {
    apiTokenForUser(customerId: $customerId, userId: $userId)
  }
`;
