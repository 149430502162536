import { may } from ".";
import type { SessionInterface } from "../store/sessionReducer";
import type { PermissionType } from "../types";
import {
  customerFeatureEnabled,
  featureEnabled,
  isAdmin,
  isCustomerAdmin,
  isRoot,
  isStaffAdmin,
  isTermitel,
  projectFeatureEnabled,
  userFeatureEnabled,
} from "./helpers";

export interface StatsMayInterface {
  (session: SessionInterface, path: "stats"): boolean;
  (session: SessionInterface, path: "stats/data-pool"): boolean;
  (session: SessionInterface, path: "stats/data-pool-by-project"): boolean;
  (session: SessionInterface, path: "stats/workers"): boolean;
  (session: SessionInterface, path: "stats/workers", action: "own"): boolean;
  (session: SessionInterface, path: "stats/tickets"): boolean;
  (session: SessionInterface, path: "stats/hotseat"): boolean;
  (session: SessionInterface, path: "stats/indeed"): boolean;
  (session: SessionInterface, path: "stats/appointments"): boolean;
  (session: SessionInterface, path: "stats/productive-hours"): boolean;
}

const StatsPermissions: PermissionType<any> = {
  stats: {
    index: (session) =>
      featureEnabled(session.currentUser, session.currentProject, "stats") ||
      may(session, "stats/data-pool") ||
      may(session, "stats/data-pool-by-project") ||
      may(session, "stats/workers") ||
      may(session, "stats/tickets") ||
      may(session, "stats/hotseat"),
  },
  "stats/data-pool": {
    index: ({ currentProject, currentUser }) =>
      featureEnabled(currentUser, currentProject, "stats") && projectFeatureEnabled(currentProject, "data_pool_stats"),
  },
  "stats/data-pool-by-project": {
    index: ({ currentProject, currentUser }) =>
      featureEnabled(currentUser, currentProject, "stats") &&
      projectFeatureEnabled(currentProject, "data_pool_by_project_stats"),
  },
  "stats/workers": {
    index: ({ currentProject, currentUser }) => featureEnabled(currentUser, currentProject, "worker_stats"),
    own: ({ currentProject, currentUser }) =>
      projectFeatureEnabled(currentProject, "worker_stats") &&
      (userFeatureEnabled(currentUser, "worker_stats") || userFeatureEnabled(currentUser, "worker_stats/own")),
  },
  "stats/tickets": {
    index: ({ currentUser }) => isAdmin(currentUser),
  },
  "stats/hotseat": {
    index: ({ currentCustomer, currentProject, currentUser }) =>
      featureEnabled(currentUser, currentProject, "hotseat/requests") ||
      (projectFeatureEnabled(currentProject, "hotseat/requests") && isCustomerAdmin(currentUser, currentCustomer)),
  },
  "stats/indeed": {
    index: ({ currentUser }) => isStaffAdmin(currentUser),
  },
  "stats/appointments": {
    index: ({ currentProject, currentUser }) =>
      featureEnabled(currentUser, currentProject, "stats") &&
      projectFeatureEnabled(currentProject, "appointment_stats"),
  },

  "stats/productive-hours": {
    index: ({ currentCustomer, currentUser }) =>
      customerFeatureEnabled(currentCustomer, "productive-hour-stats") &&
      (isRoot(currentUser) || (isCustomerAdmin(currentUser, currentCustomer) && isTermitel(currentUser))),
  },
};

export default StatsPermissions;
