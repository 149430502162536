import { RS_API_URI } from "../api/session";
import { SessionInterface } from "../store/sessionReducer";
import {
  ChangeRequestType,
  CoachingType,
  CompanyNewsType,
  EmployeeCouponType,
  EmployeeInterface,
  PersonalFileInterface,
  PersonalFileTypeType,
  PresettlementInterface,
  QueryStringType,
  SettlementInterface,
  UserInterface,
  VacationRequestInterface,
  WorktimeChangeRequestType,
  WorktimeInterface,
} from "../types";
import { queryString, sessionPrefix } from "./helpers";

export const staffPath = (session: SessionInterface) => `${sessionPrefix(session)}/staff`;

export const worktimesPath = (session: SessionInterface) => `${sessionPrefix(session)}/staff/worktimes`;
export const showWorktimePath = (session: SessionInterface, worktime: WorktimeInterface) =>
  `${sessionPrefix(session)}/staff/worktimes/${worktime.id}`;
export const newWorktimePath = (session: SessionInterface) => `${sessionPrefix(session)}/staff/worktimes/new`;
export const editWorktimePath = (session: SessionInterface, worktime: WorktimeInterface) =>
  `${sessionPrefix(session)}/staff/worktimes/${worktime.id}/edit`;

export const staffWorktimeChangeRequestsPath = (session: SessionInterface) =>
  `${sessionPrefix(session)}/staff/worktime-change-requests`;
export const showStaffWorktimeChangeRequestPath = (session: SessionInterface, worktime: WorktimeChangeRequestType) =>
  `${sessionPrefix(session)}/staff/worktime-change-requests/${worktime.id}`;
export const newStaffWorktimeChangeRequestPath = (session: SessionInterface) =>
  `${sessionPrefix(session)}/staff/worktime-change-requests/new`;
export const editStaffWorktimeChangeRequestPath = (session: SessionInterface, worktime: WorktimeChangeRequestType) =>
  `${sessionPrefix(session)}/staff/worktime-change-requests/${worktime.id}/edit`;

export const staffSupervisorPath = (session: SessionInterface) => `${sessionPrefix(session)}/staff/supervisor`;

export const staffEmployeesPath = (session: SessionInterface) => `${sessionPrefix(session)}/staff/employees`;
export const staffCondensedEmployeesPath = (session: SessionInterface) =>
  `${sessionPrefix(session)}/staff/employees/condensed`;
export const newEmployeePath = (session: SessionInterface) => `${sessionPrefix(session)}/staff/employees/new`;
export const showEmployeePath = (session: SessionInterface, employee: EmployeeInterface) =>
  `${sessionPrefix(session)}/staff/employees/${employee.id}`;
export const terminateEmployeePath = (session: SessionInterface, employee: EmployeeInterface) =>
  `${sessionPrefix(session)}/staff/employees/${employee.id}/terminate`;

export const staffFileTypesPath = (session: SessionInterface) => `${sessionPrefix(session)}/staff/file-types`;
export const newFileTypePath = (session: SessionInterface) => `${sessionPrefix(session)}/staff/file-types/new`;
export const editFileTypePath = (session: SessionInterface, fileType: PersonalFileTypeType) =>
  `${sessionPrefix(session)}/staff/file-types/${fileType.id}`;
export const staffFileTypesMissingPath = (session: SessionInterface) =>
  `${sessionPrefix(session)}/staff/file-types/missing`;

export const personalFileDownloadPath = (file: PersonalFileInterface) =>
  `${RS_API_URI}/staff/personal-files/${file.id}`;

export const vacationPath = (session: SessionInterface) => `${sessionPrefix(session)}/staff/vacation`;
export const vacationOverviewPath = (session: SessionInterface) => `${sessionPrefix(session)}/staff/vacation/overview`;
export const vacationPlanPath = (session: SessionInterface) => `${sessionPrefix(session)}/staff/vacation/plan`;
export const newVacationPath = (session: SessionInterface) => `${sessionPrefix(session)}/staff/vacation/new`;
export const showVacationPath = (session: SessionInterface, request: VacationRequestInterface) =>
  `${sessionPrefix(session)}/staff/vacation/${request.id}`;
export const editVacationPath = (session: SessionInterface, request: VacationRequestInterface) =>
  `${sessionPrefix(session)}/staff/vacation/${request.id}/edit`;

export const presettlementsPath = (session: SessionInterface) => `${sessionPrefix(session)}/staff/presettlements`;
export const newPresettlementPath = (session: SessionInterface) => `${sessionPrefix(session)}/staff/presettlements/new`;
export const showPresettlementPath = (session: SessionInterface, presettlement: PresettlementInterface) =>
  `${sessionPrefix(session)}/staff/presettlements/${presettlement.id}`;
export const editPresettlementPath = (session: SessionInterface, presettlement: PresettlementInterface) =>
  `${sessionPrefix(session)}/staff/presettlements/${presettlement.id}/edit`;

export const settlementsPath = (session: SessionInterface) => `${sessionPrefix(session)}/staff/settlements`;
export const newSettlementPath = (session: SessionInterface, qs: QueryStringType = null) =>
  `${sessionPrefix(session)}/staff/settlements/new${queryString(qs)}`;
export const editSettlementPath = (session: SessionInterface, settlement: SettlementInterface) =>
  `${sessionPrefix(session)}/staff/settlements/${settlement.id}/edit`;

export const timesheetsPath = (session: SessionInterface) => `${sessionPrefix(session)}/staff/timesheets`;
export const showTimesheetPath = (session: SessionInterface, user: UserInterface) =>
  `${sessionPrefix(session)}/staff/timesheets/${user.id}`;
export const showTimesheetYearPath = (session: SessionInterface, user: UserInterface) =>
  `${sessionPrefix(session)}/staff/timesheets/${user.id}/year`;
export const showTimesheetMonthPath = (session: SessionInterface, user: UserInterface) =>
  `${sessionPrefix(session)}/staff/timesheets/${user.id}/month`;

export const companyNewsPath = (session: SessionInterface) => `${sessionPrefix(session)}/staff/company-news/`;
export const newCompanyNewsPath = (session: SessionInterface) => `${sessionPrefix(session)}/staff/company-news/new`;
export const editCompanyNewsPath = (session: SessionInterface, news: CompanyNewsType) =>
  `${sessionPrefix(session)}/staff/company-news/${news.id}`;

export const coachingsPath = (session: SessionInterface) => `${sessionPrefix(session)}/staff/coachings/`;
export const newCoachingPath = (session: SessionInterface) => `${sessionPrefix(session)}/staff/coachings/new`;
export const showCoachingPath = (session: SessionInterface, coaching: CoachingType) =>
  `${sessionPrefix(session)}/staff/coachings/${coaching.id}`;
export const editCoachingPath = (session: SessionInterface, coaching: CoachingType) =>
  `${sessionPrefix(session)}/staff/coachings/${coaching.id}/edit`;

export const staffCouponsPath = (session: SessionInterface) => `${sessionPrefix(session)}/staff/coupons`;
export const newStaffCouponPath = (session: SessionInterface) => `${sessionPrefix(session)}/staff/coupons/new`;
export const editStaffCouponPath = (session: SessionInterface, coupon: EmployeeCouponType) =>
  `${sessionPrefix(session)}/staff/coupons/${coupon.id}/edit`;

export const staffPerformancesPath = (session: SessionInterface) => `${sessionPrefix(session)}/staff/performance`;
export const staffPerformancePath = (session: SessionInterface, user: UserInterface) =>
  `${sessionPrefix(session)}/staff/performance/${user.id}`;

export const staffChangeRequestsPath = (session: SessionInterface) => `${sessionPrefix(session)}/staff/change-requests`;
export const staffChangeRequestPath = (session: SessionInterface, changeRequest: ChangeRequestType) =>
  `${sessionPrefix(session)}/staff/change-requests/${changeRequest.id}`;
export const editStaffChangeRequestPath = (session: SessionInterface, changeRequest: ChangeRequestType) =>
  `${sessionPrefix(session)}/staff/change-requests/${changeRequest.id}/edit`;
export const newStaffChangeRequestPath = (session: SessionInterface) =>
  `${sessionPrefix(session)}/staff/change-requests/new`;
export const createUserFromChangeRequestPath = (session: SessionInterface, changeRequest: ChangeRequestType) =>
  `${sessionPrefix(session)}/staff/change-requests/${changeRequest.id}/create-user`;
